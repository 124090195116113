import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { ROUTES } from 'routes/routes';
import { UsersStore } from 'stores/UsersStore';
import AppRouter from 'stores/AppRouter';
import { User } from 'types/entities';
import { DEFAULT_USERS_SORTING } from 'pages/config';

import Button from 'components/Button/Button';
import SearchField from 'components/Form/Fields/Search/SearchField';
import Grid from 'components/Grid/Grid';
import { Types } from 'components/Grid/types';
import { UserItem } from 'pages/UsersPage/UserItem';

import style from './UsersPage.module.scss';

export const usersConfig: Types<User> = {
  columns: [
    {
      header: <FormattedMessage id="title.firstName" />,
      id: 'firstName',
      sortable: true,
    },
    {
      header: <FormattedMessage id="title.lastName" />,
      id: 'lastName',
      sortable: true,
    },
    {
      header: <FormattedMessage id="title.email" />,
      id: 'email',
      sortable: true,
    },
    {
      header: <FormattedMessage id="title.nickname" />,
      id: 'personAmount',
      sortable: true,
    },
    {
      header: <FormattedMessage id="title.company" />,
      id: 'company',
      sortable: true,
    },
    {
      header: <FormattedMessage id="title.department" />,
      id: 'department',
      sortable: true,
    },
    {
      header: <FormattedMessage id="title.priceGroup" />,
      id: 'priceGroup',
      sortable: true,
    },
    {
      header: <FormattedMessage id="title.kostenstelle" />,
      id: 'kostenStelleNr',
      sortable: true,
    },
  ],
  item: UserItem,
};

@observer
class UsersPage extends Component {
  @observable store = new UsersStore();

  onCreateUser = () => {
    AppRouter.push(ROUTES.user, {
      id: '',
    });
  };

  onUpdateUser = (user: User) => {
    AppRouter.push(ROUTES.user, {
      id: user.id,
    });
  };

  async componentDidMount() {
    await this.store.fetchUsers();
  }

  render() {
    return (
      <>
        <div className={style.header}>
          <Button
            styleType="ghost"
            className={style.btnNewUser}
            onClick={this.onCreateUser}
          >
            <FormattedMessage id="title.newUser" />
          </Button>
          <SearchField
            onChange={this.store.setSearch}
            value={this.store.searchValue}
          />
        </div>
        <main className={style.main}>
          <Grid
            loading={this.store.isLoading}
            data={this.store.filteredUsers}
            config={usersConfig}
            onRowClick={this.onUpdateUser}
            className={style.grid}
            defaultSorted={DEFAULT_USERS_SORTING}
          />
        </main>
      </>
    );
  }
}

export default UsersPage;
