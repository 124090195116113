const URLS = {
  config: '/config',
  locale: `/assets/i18n/:locale.json`,
  resource: `/resource/:id`,

  secretaries: '/users/secretary',
  passwordRecovery: '/users/recoveryPassword',
  changePassword: '/users/:id/changePassword',

  authToken: '/oauth/token',

  arrangements: '/arrangements',
  arrangementById: '/arrangements/:id',

  menu: '/menuItems',
  menuItemById: '/menuItems/:id',
  menuCategories: '/menuCategories',

  rooms: '/rooms',
  roomById: '/rooms/:id',

  events: '/events',
  eventsForPeriod: '/events/period',
  eventsScheduler: '/events/scheduler',
  eventsSchedulerForPeriod: '/events/period/scheduler',
  eventById: '/events/:id',
  eventAccept: '/events/:id/accept',
  eventApprove: '/events/:id/approve',
  eventCancel: '/events/:id/cancel',
  eventDecline: '/events/:id/decline',
  eventExtraMenu: '/events/:id/extraMenu',
  eventReport: '/reports/event/:id/summary/pdf',

  notifications: '/notifications',
  notificationById: '/notifications/:id',

  invoices: '/invoices',
  invoicesForPeriod: '/invoices/period',
  invoiceById: '/invoices/:id',
  invoiceAccept: '/invoices/:id/accept',
  invoiceApprove: '/invoices/:id/approve',
  invoiceDecline: '/invoices/:id/decline',
  invoiceReport: '/reports/invoice/:id/pdf',

  statisticsCaterer: '/statistics/caterer',
  statisticsSecretary: '/statistics/secretary',

  reports: '/reports/invoice/summary',
  reportPDF: '/reports/invoice/summary/pdf',

  users: '/users',
  usersSecretary: '/users/secretary',
  user: '/users/:id',

  roles: '/roles',
};

export default URLS;
