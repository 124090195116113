import React, { Component } from 'react';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import {
  FormattedMessage,
  FormattedDate,
  FormattedTime,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';

import { dateAdd } from 'helpers/datetime';
import BookingStore from 'stores/Booking/BookingStore';
import RootStore from 'stores/RootStore';
import RoomModel from 'stores/Models/RoomModel';

import Navigation from './Navigation/Navigation';
import Button from 'components/Button/Button';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';
import Spinner from 'components/Spinner/Spinner';
import CheckboxField from 'components/Form/Fields/Checkbox/CheckboxField';
import TextField from 'components/Form/Fields/TextField/TextField';
import Icon from 'components/Icon/Icon';
import RoomSummary from 'pages/BookingPage/RoomSummary/RoomSummary';

import style from './BookingPage.module.scss';
import { EventReservation } from 'types/entities';
import { Field } from 'components/Form/Field/Field';

export const isParticipantsRequired = (reservation: EventReservation) => {
  return reservation.menu.some(item => item.isParticipantsRequired);
};

interface Props extends WrappedComponentProps {
  store: BookingStore;
}

@observer
class Summary extends Component<Props> {
  @observable hasTriedToSubmit = false;

  @action.bound
  onChange(value: string, { target }) {
    const { store } = this.props;
    const { name } = target;

    store.event[name] = value;
  }

  @action.bound
  onCheck(value, { target }) {
    const { store } = this.props;
    store.event[target.name] = value;
  }

  @computed
  get isRulesChecked() {
    const { acceptAGB, acceptCorona } = this.props.store.event;

    return acceptAGB && acceptCorona;
  }

  @computed
  get isParticipantsFilled() {
    const { reservations } = this.props.store.event;

    return reservations.every(reservation =>
      isParticipantsRequired(reservation) ? !!reservation.participants : true,
    );
  }

  @computed
  get canBook() {
    const { title } = this.props.store.event;

    return this.isRulesChecked && Boolean(title) && this.isParticipantsFilled;
  }

  @action.bound
  onSubmit() {
    const { store } = this.props;
    this.hasTriedToSubmit = true;

    if (!this.canBook) {
      return;
    }

    store.submit();
  }

  render() {
    const { intl, store } = this.props;
    const { stepper } = store;
    const showTitleValidationError =
      this.hasTriedToSubmit && !store.event.title;

    return (
      <div className={style.page}>
        {store.pending && <Spinner contain />}
        <section className={style.navigation}>
          <div className={style.navigationRow}>
            <Navigation store={stepper} />
          </div>

          <div className={style.summary__content}>
            <div className={style.summary__text}>
              <FormattedMessage id="booking.summary.text" />
            </div>

            <div className={style.summary__data}>
              <div className={style.data__line}>
                <Field.Wrapper>
                  <TextField
                    id="title"
                    name="title"
                    type="text"
                    maxLength={128}
                    value={store.event.title}
                    onChange={this.onChange}
                    placeholder={intl.formatMessage({
                      id: 'event.input.title.placeholder',
                    })}
                    invalid={showTitleValidationError}
                  />
                  <Field.Error
                    show={showTitleValidationError}
                    messageId="event.input.title.placeholder"
                  />
                </Field.Wrapper>
              </div>
              <div className={style.data__line}>
                <Icon type="calendar" className={style.data__icon} />
                <FormattedDate
                  value={store.event.start}
                  year="numeric"
                  month="long"
                  day="2-digit"
                />
              </div>
              <div className={style.data__line}>
                <Icon type="time" className={style.data__icon} />
                <FormattedTime value={store.event.start} />
                &nbsp;-&nbsp;
                <FormattedTime
                  value={dateAdd(
                    store.event.start,
                    store.event.duration,
                    'minute',
                  )}
                />
              </div>
            </div>

            <div className={style.rules}>
              <div className={style.rule}>
                <CheckboxField
                  checked={store.event.acceptAGB}
                  onChange={this.onCheck}
                  name="acceptAGB"
                  invalid={this.hasTriedToSubmit && !store.event.acceptAGB}
                >
                  <FormattedMessage
                    id="event.agb.link"
                    values={{
                      AGB: (
                        <a
                          href={RootStore.config.agbRules}
                          className={style.checkboxLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          AGBs
                        </a>
                      ),
                    }}
                  />
                </CheckboxField>
              </div>
              <div className={style.rule}>
                <CheckboxField
                  checked={store.event.acceptCorona}
                  onChange={this.onCheck}
                  name="acceptCorona"
                  invalid={this.hasTriedToSubmit && !store.event.acceptCorona}
                >
                  <FormattedMessage
                    id="event.corona.link"
                    values={{
                      Corona: (
                        <a
                          href={RootStore.config.coronaRules}
                          className={style.checkboxLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Schutz- und Hygienekonzept
                        </a>
                      ),
                    }}
                  />
                </CheckboxField>
              </div>
            </div>

            <div className={style.summary__controls}>
              <div className={style.summary__info}>
                <span className={style.summary__title}>
                  <FormattedMessage id="booking.total" />
                </span>
                <span className={style.summary__total}>
                  <FormattedCurrency value={store.totalPrice} />
                </span>
              </div>

              <Button
                className={style.btn}
                styleType="accept"
                onClick={this.onSubmit}
              >
                <FormattedMessage id="button.booking" />
              </Button>
            </div>
          </div>
        </section>

        <section className={style.summary__main}>
          <div className={style.totalTitle}>
            <FormattedMessage id="booking.summary.totalColumn.title" />
          </div>
          {store.event.reservations.map(reservation => {
            const roomModel = store.rooms.find(
              it => it.id === reservation.room.id,
            ) as RoomModel;

            return (
              <RoomSummary
                key={reservation.room.id}
                reservation={reservation}
                roomPrice={roomModel.price(store.event.duration)}
                showValidationErrors={this.hasTriedToSubmit}
              />
            );
          })}
        </section>
      </div>
    );
  }
}

export default injectIntl(Summary);
