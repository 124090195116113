import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import style from './ProductCard.module.scss';

import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';
import Icon from 'components/Icon/Icon';
import Button from 'components/Button/Button';

import { appUrl } from 'http/helpers';
import Spinner from '../Spinner/Spinner';

interface Props {
  image: string;
  title: string;
  description: string | JSX.Element;
  price?: number;
  checked?: boolean;
  className?: string;
  onClick?: (event?) => void;
}

@observer
class ProductCard extends Component<Props> {
  @observable isLoading = true;

  onImageLoad = () => {
    this.isLoading = false;
  };

  render() {
    const { image, title, description, price, className, ...card } = this.props;
    const withPrice = price || price === 0;

    return (
      <figure
        className={classNames(style.card, className, {
          [style.card__checked]: card.checked,
          [style.card__withPrice]: withPrice,
        })}
      >
        <div className={style.wrapper__image}>
          <img
            className={style.image}
            onLoad={this.onImageLoad}
            src={appUrl(image)}
            alt="space"
          />

          {this.isLoading && <Spinner className={style.spinner} />}
        </div>

        <figcaption className={style.figcaption}>
          <div className={style.wrapper__btn}>
            <Button className={style.btn__status} onClick={card.onClick}>
              <Icon type="accept" className={style.tik} />
              <FormattedMessage
                id={card.checked ? 'state.checked' : 'state.unchecked'}
              />
            </Button>
          </div>

          <div className={style.captionWrapper}>
            <div className={style.descriptionWrapper}>
              <p className={style.name} title={title}>
                {title}
              </p>

              {description && (
                <p className={style.description}>{description}</p>
              )}
            </div>

            {withPrice && (
              <p className={style.price}>
                <FormattedCurrency value={price!} />
              </p>
            )}
          </div>
        </figcaption>
      </figure>
    );
  }
}

export default ProductCard;
