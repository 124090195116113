import { action, computed, observable } from 'mobx';

import { showHttpErrors } from 'helpers/errors';
import { getRole, Roles } from 'helpers/roles';
import { dateAdd } from 'helpers/datetime';
import Log from 'helpers/log';
import httpFacade from 'http/httpFacade';
import { CreateEventRequestData, EditEventRequestData } from 'http/Api/events';
import { ROUTES } from 'routes/routes';
import ModalStore from 'stores/ModalStore';
import EventModel from 'stores/Models/EventModel';
import AppRouter from 'stores/AppRouter';
import { getReservationsData } from 'stores/EventStore/helpers';
import { EventStatus } from 'types/entities';

import CommentModal from 'components/Modals/CommentModal/CommentModal';

const HOURS_BEFORE_EVENT_TO_BLOCK_EDITING = 24;

function isAllowedToCancelEventByTime(eventStart: Date): boolean {
  return (
    new Date() <=
    dateAdd(eventStart, -HOURS_BEFORE_EVENT_TO_BLOCK_EDITING, 'hour')
  );
}

class EventStore {
  @observable event: EventModel = new EventModel();
  @observable pending = true;

  @computed get canCancel() {
    return (
      (getRole(Roles.SECRETARY) || getRole(Roles.MASTER_BOOKING)) &&
      (this.event.status === EventStatus.declined ||
        this.event.status === EventStatus.inApproval ||
        (this.event.status === EventStatus.accepted &&
          isAllowedToCancelEventByTime(this.event.start)))
    );
  }

  @computed get canAccept() {
    return (
      getRole(Roles.CATERER_MANAGER) &&
      this.event.status === EventStatus.inApproval
    );
  }

  @computed get canDecline() {
    return (
      getRole(Roles.CATERER_MANAGER) &&
      this.event.status === EventStatus.inApproval
    );
  }

  @computed get canEdit() {
    return this.event.canEdit;
  }

  @computed get canEditMenu() {
    return this.event.canEditCatering;
  }

  @action.bound
  async fetchEvent(id: string) {
    this.pending = true;

    try {
      const response = await httpFacade.events.fetchEventById(id);

      this.event.update(response.data);
    } catch (error) {
      Log.info(error);
    }

    this.pending = false;
  }

  @action.bound
  async createEvent() {
    const event: CreateEventRequestData = {
      duration: this.event.duration,
      personAmount: this.event.personAmount,
      reservations: getReservationsData(this.event.reservations),
      start: this.event.start.toISOString(),
      title: this.event.title,
    };

    await httpFacade.events.createEvent(event, this.event.owner.id);
  }

  @action.bound
  async editEvent() {
    const event: EditEventRequestData = {
      duration: this.event.duration,
      personAmount: this.event.personAmount,
      reservations: getReservationsData(this.event.reservations),
      start: this.event.start.toISOString(),
      title: this.event.title,
      version: this.event.version || 0,
    };

    const response = await httpFacade.events.editEvent(
      this.event.id,
      event,
      this.event.owner.id,
    );

    if (
      response.data.status === EventStatus.declined &&
      getRole(Roles.SECRETARY)
    ) {
      await httpFacade.events.approveEvent(
        response.data.id,
        response.data.version || 0,
      );
    }
  }

  @action.bound
  toEditPage() {
    AppRouter.push(ROUTES.bookingEdit, { id: this.event.id });
  }

  @action.bound
  toEditMenuPage() {
    AppRouter.push(ROUTES.cateringEdit, {
      id: this.event.id,
      roomId: this.event.selectedReservation.room.id,
    });
  }

  @action.bound
  async acceptEvent() {
    try {
      await httpFacade.events.acceptEvent(
        this.event.id,
        this.event.version || 0,
      );

      AppRouter.goBack();
    } catch (error) {
      showHttpErrors(error);
    }
  }

  @action.bound
  async cancelEvent() {
    try {
      await httpFacade.events.cancelEvent(
        this.event.id,
        this.event.version || 0,
        this.event.owner.id,
      );

      AppRouter.goBack();
    } catch (error) {
      showHttpErrors(error);
    }
  }

  @action.bound
  async declineEvent() {
    try {
      const { payload } = await ModalStore.showModal(CommentModal, {
        inputLabel: 'modal.declineEvent.input.label',
        inputPlaceholder: 'modal.declineEvent.input.placeholder',
        okBtnText: 'button.reject',
        cancelBtnText: 'button.abort',
      });

      try {
        await httpFacade.events.declineEvent(
          this.event.id,
          payload,
          this.event.version || 0,
        );

        AppRouter.goBack();
      } catch (error) {
        showHttpErrors(error);
      }
    } catch (error) {
      // nothing, so good
    }
  }

  @action.bound
  async fetchEventReport() {
    try {
      const { data } = await httpFacade.invoices.fetchEventReport(
        this.event.id,
      );

      window.open(URL.createObjectURL(data));
    } catch (error) {
      showHttpErrors(error);
    }
  }
}

export default EventStore;
