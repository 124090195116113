import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { CurrencyValue, MenuItem } from 'types/entities';
import { PERCENTAGE_OF_SERVICE_COST } from 'stores/Booking/BookingStore';

import SummaryProduct from '../PanelProducts/SummaryProduct';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';

import style from './SummaryPanel.module.scss';

interface Props {
  products: MenuItem[];
  roomInfo: {
    title: string;
    price: CurrencyValue;
  };
  splitMode?: boolean;
  className?: string;
}

const ROOMS_NUMBER = 1;

const SummaryPanel = (props: Props) => {
  const { products, roomInfo, splitMode, className } = props;
  const serviceCost = roomInfo.price * PERCENTAGE_OF_SERVICE_COST;

  const mainMenu: MenuItem[] = [];
  const extraMenu: MenuItem[] = [];

  if (splitMode) {
    products.forEach(item =>
      item.extra ? extraMenu.push(item) : mainMenu.push(item),
    );
  }

  return (
    <div className={classNames(style.panel, className)}>
      <div className="caption grid-row">
        <div className="column">
          <FormattedMessage id="title.order" />
        </div>

        <div className="column">
          <FormattedMessage id="title.amount" />
        </div>

        <div className="column">
          <FormattedMessage id="title.price" />
        </div>

        <div className="column">
          <FormattedMessage id="title.total" />
        </div>
      </div>

      <div className={classNames(style.products)}>
        <div className={classNames(style.product, 'grid-row')}>
          <div className="column">{roomInfo.title}</div>

          <div className="column">{ROOMS_NUMBER}</div>

          <div className="column">
            <FormattedCurrency value={roomInfo.price} />
          </div>

          <div className="column">
            <FormattedCurrency value={roomInfo.price} />
          </div>
        </div>

        {Boolean(serviceCost) && (
          <div className={classNames(style.product, 'grid-row')}>
            <div className="column">
              <FormattedMessage id="title.service.cost" />
            </div>

            <div className="column">{ROOMS_NUMBER}</div>

            <div className="column">
              <FormattedCurrency value={serviceCost} />
            </div>

            <div className="column">
              <FormattedCurrency value={serviceCost} />
            </div>
          </div>
        )}

        {splitMode ? (
          <>
            {mainMenu.map(product => (
              <SummaryProduct
                product={product}
                key={product.id}
                className={style.product}
              />
            ))}

            {!!extraMenu.length && (
              <>
                <div className="caption__extra">
                  <FormattedMessage id="additional.menu" />
                </div>

                {extraMenu.map(product => (
                  <SummaryProduct
                    product={product}
                    key={product.id}
                    className={style.product}
                  />
                ))}
              </>
            )}
          </>
        ) : (
          products.map(product => (
            <SummaryProduct
              product={product}
              key={product.id}
              className={style.product}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default SummaryPanel;
