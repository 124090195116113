import React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import style from './Planner.module.scss';

import Icon from 'components/Icon/Icon';
import Button from 'components/Button/Button';

import { Event } from './types';
import { MILLISECONDS_PER_MINUTE } from 'helpers/datetime';

import { MAILS_FOR_BOOKING } from 'stores/Booking/config';

interface EventStates {
  current?: boolean;
  selected?: boolean;
  disabled?: boolean;
  blockedForOnlineBooking?: boolean;
}

interface Props {
  event: Event & EventStates;
  title: string;
  onSelect: (roomId: string) => void;
}

const EventFree = observer(({ event, onSelect }: Props) => {
  const preparationHeight =
    ((event.preparation * MILLISECONDS_PER_MINUTE) /
      (+event.endCleaning - +event.startPreparation)) *
    100;
  const cleaningHeight =
    ((event.cleaning * MILLISECONDS_PER_MINUTE) /
      (+event.endCleaning - +event.startPreparation)) *
    100;
  const eventHeight = 100 - preparationHeight - cleaningHeight;

  return (
    <div
      className={classNames(
        style.eventChooseWrapper,
        event.current && style.eventCurrentWrapper,
      )}
    >
      <svg
        className={style.dashBorder}
        viewBox="0 0 300 100"
        preserveAspectRatio="none"
      >
        <path
          d="M0,0 300,0 300,100 0,100z"
          vectorEffect="non-scaling-stroke"
          strokeLinejoin="round"
        />
      </svg>

      <Button
        styleType="text"
        className={classNames(style.eventChooseContent, {
          [style.eventChooseContent__selected]: event.selected,
          [style.eventChooseContent__blocked]: event.blockedForOnlineBooking,
        })}
        style={{ top: `${preparationHeight}%`, height: `${eventHeight}%` }}
        onClick={() => onSelect(event.roomId)}
        disabled={event.disabled || event.blockedForOnlineBooking}
      >
        {event.blockedForOnlineBooking ? (
          <div className={style.event__info}>
            <FormattedMessage id="event.booking.blocked" />

            {MAILS_FOR_BOOKING.map(mail => (
              <a
                key={mail}
                href={`mailto:${mail}`}
                className={style.link__mail}
              >
                {mail}
              </a>
            ))}
          </div>
        ) : event.selected ? (
          <>
            <Icon type="pinned" />
            <FormattedMessage id={'event.button.chosen'} />
          </>
        ) : (
          <>
            <Icon type="pin" />
            <FormattedMessage id={'event.button.choose'} />
          </>
        )}
      </Button>
    </div>
  );
});

export default EventFree;
