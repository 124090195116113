import React, { Component } from 'react';
import { observer } from 'mobx-react';

import style from './FavoriteMenu.module.scss';

// import Slider from 'components/Slider/Slider';
// import FavoriteMenuItem from './FavoriteMenuItem';

// import FavoriteMenuModel from 'stores/Models/FavoriteMenu';
import AppRouter from 'stores/AppRouter';

import { MenuSet } from 'types/entities';
import { ROUTES } from 'routes/routes';

// const sliderResponsiveConfig = [
//   {
//     breakpoint: 1764,
//     settings: {
//       slidesToShow: 5,
//     },
//   },
//   {
//     breakpoint: 1518,
//     settings: {
//       slidesToShow: 4,
//     },
//   },
//   {
//     breakpoint: 1284,
//     settings: {
//       slidesToShow: 3,
//     },
//   },
//   {
//     breakpoint: 1023,
//     settings: {
//       slidesToShow: 2,
//     },
//   },
//   {
//     breakpoint: 779,
//     settings: {
//       slidesToShow: 1,
//     },
//   },
// ];

// TODO: uncomment after removing the mask

@observer
class FavoriteMenu extends Component {
  // favoriteMenu = new FavoriteMenuModel();

  async componentDidMount() {
    // await this.favoriteMenu.fetchFoodPackages();
  }

  chooseMenuItemHandler = (menuItem: MenuSet) => () => {
    AppRouter.router.push({
      pathname: ROUTES.bookingNew,
      state: {
        menuId: menuItem.id,
      },
    });
  };

  render() {
    return (
      <div className={style.slider__wrapper}>
        <div className={style.mask}>
          <div className={style.mask__image} />
          <div className={style.mask__image} />
          <div className={style.mask__image} />
          <div className={style.mask__image} />
          <div className={style.mask__image} />
        </div>

        {/*<Slider*/}
        {/*  rows={2}*/}
        {/*  slidesToShow={3}*/}
        {/*  slidesToScroll={1}*/}
        {/*  className={style.slider}*/}
        {/*  infinite={false}*/}
        {/*  responsive={sliderResponsiveConfig}*/}
        {/*  key={this.favoriteMenu.packages.length}*/}
        {/*>*/}
        {/*  {this.favoriteMenu.packages.map(menuItem => (*/}
        {/*    <FavoriteMenuItem*/}
        {/*      key={menuItem.id}*/}
        {/*      menuItem={menuItem}*/}
        {/*      onClick={this.chooseMenuItemHandler(menuItem)}*/}
        {/*    />*/}
        {/*  ))}*/}
        {/*</Slider>*/}
      </div>
    );
  }
}

export default FavoriteMenu;
