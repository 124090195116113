import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { dateAdd } from 'helpers/datetime';
import { getRole, Roles } from 'helpers/roles';
import { ROUTES } from 'routes/routes';
import EventStore from 'stores/EventStore/EventStore';
import AppRouter from 'stores/AppRouter';
import { CanEditCateringType, InvoiceStatus } from 'types/entities';

import { Tabs } from 'components/Tabs/Tabs';
import SummaryPanel from 'components/Panels/Summary/SummaryPanel';
import Button from 'components/Button/Button';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';
import Icon from 'components/Icon/Icon';
import Spinner from 'components/Spinner/Spinner';
import StatusBar from './StatusBar';
import Controls from './Controls';

import style from './EventPage.module.scss';

type Props = RouteComponentProps<{ id: string }>;

@observer
class EventPage extends Component<Props> {
  event: EventStore = new EventStore();

  async componentDidMount() {
    const id = this.props.match.params && this.props.match.params.id;

    await this.event.fetchEvent(id);
  }

  render() {
    const { event } = this.event;
    const { selectedReservation } = event;
    const isStaff = getRole(Roles.CATERER_STAFF);

    return this.event.pending ? (
      <Spinner />
    ) : (
      <>
        <div className={style.header}>
          <div className={style.header__info}>
            <div className={style.eventData}>
              <Button className={style.btn__arrow} onClick={AppRouter.goBack}>
                <Icon type="arrowLeft" />
              </Button>
              <div className={style.data}>
                <p className={style.name}>{event.title}</p>
                <p className={style.date}>
                  <FormattedDate
                    value={event.start}
                    day="2-digit"
                    month="long"
                    year="numeric"
                  />
                  {' | '}
                  <FormattedTime value={event.start} />
                  {' - '}
                  <FormattedTime
                    value={dateAdd(event.start, event.duration, 'minute')}
                  />
                </p>
              </div>
              {!isStaff && !event.invoice && (
                <StatusBar status={event.status} className={style.status} />
              )}
              {event.comment && (
                <div>
                  <div
                    className={classNames(
                      style.propertyTitle,
                      style.reasonTitle,
                    )}
                  >
                    <FormattedMessage id="event.reason" />
                  </div>
                  <div className={style.propertyValue}>{event.comment}</div>
                </div>
              )}
              {!isStaff && event.invoice && (
                <Link
                  to={{
                    pathname: ROUTES.menuInvoices,
                    hash: event.invoice.id,
                  }}
                  className={style.invoice}
                >
                  <Icon type="bill" className={style.icon__bill} />
                  {event.invoice.status === InvoiceStatus.declined && (
                    <Icon
                      type="notification"
                      className={style.icon__attention}
                    />
                  )}
                  <FormattedMessage id="settlements" />
                  &nbsp;
                  <span className={style.invoiceID}>
                    {event.invoice.number}
                  </span>
                </Link>
              )}
            </div>
            <div className={style.price}>
              <span className={style.total}>
                <FormattedMessage id="booking.total" />
              </span>
              <FormattedCurrency value={event.totalPrice} />
            </div>
            <Controls event={this.event} />
          </div>
          <Tabs
            tabs={event.reservations.map(reservation => ({
              id: reservation.room.id,
              title: reservation.room.title,
            }))}
            onTabClick={value => (event.selectedRoomIdToSetup = value)}
            activeTabId={event.selectedRoomIdToSetup}
          />
        </div>
        <div className={style.content}>
          <div className={style.information}>
            {!event.canEdit &&
              this.event.canEditMenu !== CanEditCateringType.none && (
                <Button
                  className={style.btn__editMenu}
                  styleType="accept"
                  onClick={this.event.toEditMenuPage}
                >
                  <FormattedMessage id="button.editMenu" />
                </Button>
              )}
            <div className={style.property}>
              <div className={style.propertyTitle}>
                <FormattedMessage id="seating" />
              </div>
              <div className={style.propertyValue}>
                {selectedReservation.arrangement.title}
              </div>
            </div>
            <div className={style.property}>
              <div className={style.propertyTitle}>
                <FormattedMessage id="comment" />
              </div>
              <div className={style.propertyValue}>
                {selectedReservation.description}
              </div>
            </div>
            <div className={style.property}>
              <div className={style.propertyTitle}>
                <FormattedMessage id="participants" />
              </div>
              <div className={style.propertyValue}>
                {selectedReservation.participants}
              </div>
            </div>
            {!isStaff && (
              <div className={style.details}>
                <div className={style.details__title}>
                  <FormattedMessage id="event.details" />
                </div>

                <div className={style.details__file}>
                  <Icon type="pdf" className={style.icon__pdf} />
                  <div>
                    <FormattedMessage id="event.report.filename" tagName="p" />
                    <Button
                      styleType="text"
                      onClick={this.event.fetchEventReport}
                      className={style.btn__viewFile}
                    >
                      <FormattedMessage id="text.view.file" />
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <SummaryPanel
            key={selectedReservation.id}
            products={selectedReservation.menu}
            roomInfo={{
              title: selectedReservation.room.title,
              price: selectedReservation.roomPrice,
            }}
            className={style.panel}
            splitMode
          />
        </div>
      </>
    );
  }
}

export default EventPage;
