import http from 'http/http';
import URLS from 'http/urls';
import { appUrl } from 'http/helpers';

import { TokensResponse, UserAuthFormData } from './types';
import { Secretary } from 'types/entities';

import { createLoginFormData, createRefreshTokenFormData } from './helpers';

export interface ChangePasswordDTO {
  oldPassword: string;
  newPassword: string;
}

export const userApi = {
  login: (data: UserAuthFormData) =>
    http.post<FormData, TokensResponse>(
      appUrl(URLS.authToken),
      createLoginFormData(data),
    ),

  refreshToken: (refreshToken: string) =>
    http.post<FormData, TokensResponse>(
      appUrl(URLS.authToken),
      createRefreshTokenFormData(refreshToken),
    ),

  secretaries() {
    return http.get<Secretary>(appUrl(URLS.secretaries));
  },

  passwordRecovery(data: { email: string }) {
    return http.post(appUrl(URLS.passwordRecovery), data);
  },

  changePassword(userId: string, data: ChangePasswordDTO) {
    return http.post<ChangePasswordDTO, unknown>(
      appUrl(URLS.changePassword),
      data,
      {
        replaceParams: { id: userId },
      },
    );
  },
};
