import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { ROUTES } from 'routes/routes';
import EventsStore from 'stores/EventsStore';
import AppRouter from 'stores/AppRouter';
import { Event } from 'types/entities';

import Grid from 'components/Grid/Grid';
import EventItem from 'components/Grid/Items/EventItem';
import { Types } from 'components/Grid/types';
import { DEFAULT_EVENT_SORTING } from 'pages/config';

import style from './StaffDashboard.module.scss';

export const eventConfig: Types<Event> = {
  columns: [
    {
      header: <FormattedMessage id="title.time" />,
      id: 'start',
      sortable: true,
    },
    {
      header: <FormattedMessage id="title.event" />,
      id: 'title',
      sortable: true,
    },
    {
      header: <FormattedMessage id="title.room" />,
      id: 'room.title',
      sortable: false,
    },
    {
      header: <FormattedMessage id="title.personAmount" />,
      id: 'personAmount',
      sortable: true,
    },
  ],
  item: EventItem,
};

@observer
class StaffDashboard extends Component {
  store = new EventsStore();

  async componentDidMount() {
    const { fetchEvents } = this.store;

    await fetchEvents();
  }

  onItemClick = item => {
    AppRouter.push(ROUTES.event, { id: item.id });
  };

  render() {
    return (
      <>
        <div className={style.navigation} />

        <div className={style.content}>
          <Grid
            loading={this.store.pending}
            data={this.store.data}
            config={eventConfig}
            className={style.grid__booking}
            onRowClick={this.onItemClick}
            onSort={config => (this.store.sortConfig = config)}
            defaultSorted={DEFAULT_EVENT_SORTING}
          />
        </div>
      </>
    );
  }
}

export default StaffDashboard;
