import { action, computed, observable, reaction } from 'mobx';

import {
  dateAdd,
  dateStartOf,
  getTime,
  getTimeFromDate,
  MILLISECONDS_PER_MINUTE,
  setTimeToDate,
  Time,
} from 'helpers/datetime';
import { uniqueId } from 'helpers/uniqueId';
import httpFacade from 'http/httpFacade';
import RootStore from 'stores/RootStore';
import { CanEditCateringType, Event, EventStatus, Room } from 'types/entities';

import BookingStore from './BookingStore';
import {
  calcEventCleaningEnd,
  getEventEnd,
  calcEventPreparationStart,
  isCateringBlockedByTime,
  isCateringEditBlocked,
} from './helpers';
import { showHttpErrors } from 'helpers/errors';
import AppRouter from 'stores/AppRouter';
import { BLOCK_FOR_BOOKING_ROOM_TITLE } from './config';
import { EventReservationModel } from 'stores/Models/EventReservationModel';

function getFirstDateTime(minPreparationDuration: number, date?: Date) {
  const now = new Date();

  if (date && +dateStartOf(now, 'day') !== +dateStartOf(date, 'day')) {
    return setTimeToDate(date, RootStore.config.eventTimeMin);
  }

  let dateTime = setTimeToDate(now, RootStore.config.eventTimeMin);
  const maxDisabledDate = dateAdd(now, minPreparationDuration, 'minute');

  while (dateTime < maxDisabledDate) {
    dateTime = dateAdd(dateTime, RootStore.config.eventStep, 'minute');
  }

  return dateTime;
}

class RoomSelectionStore {
  @observable pending: boolean = true;
  @observable onlyAvailable: boolean = true;
  @observable showTermError = false;
  @observable showAvailableError = false;

  @observable dateTime: Date = new Date();
  protected readonly bookingStore: BookingStore;

  @observable private _events: Event[] = [];
  @observable private _duration: number = RootStore.config.eventDurationDefault;

  private readonly currentEventId?: string;

  @computed
  get currentEvent() {
    return this.currentEventId
      ? this._events.find(event => event.id === this.currentEventId)
      : undefined;
  }

  @computed
  get date(): Date {
    return new Date(
      this.dateTime.getFullYear(),
      this.dateTime.getMonth(),
      this.dateTime.getDate(),
    );
  }

  set date(value: Date) {
    if (!this.canEditByTerm) {
      this.showTermError = true;
    } else {
      const dateTime = new Date(
        value.getFullYear(),
        value.getMonth(),
        value.getDate(),
        this.dateTime.getHours(),
        this.dateTime.getMinutes(),
        this.dateTime.getSeconds(),
      );

      if (this.acceptByTerm(this.bookingStore.event, dateTime)) {
        this.dateTime =
          dateTime.getTime() < Date.now()
            ? getFirstDateTime(
                this.bookingStore.minPreparationDuration,
                dateTime,
              )
            : dateTime;
      } else {
        this.showTermError = true;
      }
    }

    this.bookingStore.event.resetReservations();
  }

  @computed
  get time(): Time {
    return getTime(
      this.dateTime.getHours(),
      this.dateTime.getMinutes(),
      this.dateTime.getSeconds(),
    );
  }

  set time(value: Time) {
    if (!this.canEditByTerm) {
      this.showTermError = true;
    } else {
      const dateTime = new Date(
        this.dateTime.getFullYear(),
        this.dateTime.getMonth(),
        this.dateTime.getDate(),
        0,
        0,
        0,
        value,
      );

      if (this.acceptByTerm(this.bookingStore.event, dateTime)) {
        this.dateTime = dateTime;
      } else {
        this.showTermError = true;
      }
    }
    this.bookingStore.event.resetReservations();
  }

  @computed
  get duration() {
    return this._duration;
  }

  set duration(duration: number) {
    if (duration !== this._duration) {
      this._duration = duration;
      this.bookingStore.event.resetReservations();
    }
  }

  @computed
  get rooms() {
    return this.onlyAvailable
      ? this.bookingStore.rooms.filter(room => {
          const startPreparation = calcEventPreparationStart(
            this.dateTime,
            room.preparation,
          );
          const endCleaning = calcEventCleaningEnd(
            this.dateTime,
            this.duration,
            room.cleaning,
          );

          return (
            room.capacity >= this.bookingStore.event.personAmount &&
            this.events.every(
              event =>
                (event.id === this.currentEventId && !event.duplicated) ||
                event.roomId !== room.id ||
                event.startPreparation >= endCleaning ||
                event.endCleaning <= startPreparation,
            )
          );
        })
      : this.bookingStore.rooms;
  }

  @computed
  get events() {
    return this._events.reduce(
      (acc, event) => {
        const startDate = new Date(event.start);
        const endDate = getEventEnd(startDate, event.duration);
        const reservations = event.reservations.map(reservation => {
          const startPreparation = calcEventPreparationStart(
            startDate,
            reservation.preparation,
          );
          const endCleaning = calcEventCleaningEnd(
            startDate,
            event.duration,
            reservation.cleaning,
          );

          return {
            ...event,
            roomId: reservation.room.id,
            preparation: reservation.preparation,
            cleaning: reservation.cleaning,
            startDate,
            endDate,
            startPreparation,
            endCleaning,
            allDay: false,
            current: event.id === this.currentEventId,
          };
        });

        acc.push(...reservations);

        return acc;
      },
      [] as any[],
    );
  }

  @computed
  get filterFull(): boolean {
    return Boolean(
      this.date &&
        this.time &&
        this.duration &&
        this.bookingStore.event.personAmount,
    );
  }

  @computed
  get availableNewEvents() {
    if (!this.filterFull) {
      return [];
    }

    const freeRooms: any = [];
    for (const room of this.bookingStore.rooms) {
      const startPreparation = calcEventPreparationStart(
        this.dateTime,
        room.preparation,
      );
      const endCleaning = calcEventCleaningEnd(
        this.dateTime,
        this.duration,
        room.cleaning,
      );

      if (
        this.events.every(
          event =>
            (event.id === this.currentEventId && !event.duplicated) ||
            event.roomId !== room.id ||
            event.startPreparation >= endCleaning ||
            event.endCleaning <= startPreparation,
        )
      ) {
        freeRooms.push({
          id: uniqueId(),
          title: '',
          allDay: false,
          duration: this.duration,
          preparation: room.preparation,
          cleaning: room.cleaning,
          startPreparation,
          endCleaning,
          roomId: room.id,
          status: EventStatus.inApproval,
          availableNew: true,
          selected: this.bookingStore.event.roomsIds.includes(room.id),
          disabled:
            !this.bookingStore.event.id && startPreparation < new Date(),
          blockedForOnlineBooking: room.title === BLOCK_FOR_BOOKING_ROOM_TITLE,
        });
      }
    }

    return freeRooms;
  }

  @computed
  get allEvents() {
    return this.filterFull
      ? [...this.events, ...this.availableNewEvents]
      : this.events;
  }

  @computed
  get timeOptionValues(): Array<{
    value: number;
    disabled: boolean;
  }> {
    const { eventTimeMin, eventTimeMax, eventStep } = RootStore.config;

    const res: any[] = [];
    let currentTime = eventTimeMin;
    const nowTime = getTimeFromDate(new Date());
    const timeMax = eventTimeMax - this.duration * MILLISECONDS_PER_MINUTE;

    const isToday = +dateStartOf(new Date(), 'day') === +this.date;

    while (currentTime <= timeMax) {
      const disabled =
        isToday &&
        +nowTime +
          this.bookingStore.minPreparationDuration * MILLISECONDS_PER_MINUTE >
          +currentTime;

      if (!disabled) {
        res.push({
          value: currentTime,
        });
      }

      currentTime += eventStep * MILLISECONDS_PER_MINUTE;
    }

    return res;
  }

  @computed
  get durationOptionValues(): Array<{ value: number }> {
    const {
      eventDurationMin,
      eventDurationMax,
      eventTimeMax,
    } = RootStore.config;
    const res: any[] = [];
    const durationMax = Math.min(
      (eventTimeMax - this.time) / MILLISECONDS_PER_MINUTE,
      eventDurationMax,
    );

    let currentValue = eventDurationMin;

    while (currentValue <= durationMax) {
      res.push({
        value: currentValue,
      });

      currentValue += eventDurationMin;
    }

    return res;
  }

  @computed get canEditByTerm() {
    return this.acceptByTerm(this.bookingStore.event);
  }

  constructor(store: BookingStore) {
    this.bookingStore = store;
    this.currentEventId = store.eventID;

    if (store.startDate) {
      this.date = store.startDate;
    }

    reaction(
      () => this.showTermError,
      value =>
        value ? setTimeout(() => (this.showTermError = false), 5000) : null,
    );

    reaction(
      () => this.showAvailableError,
      value =>
        value
          ? setTimeout(() => (this.showAvailableError = false), 5000)
          : null,
    );

    reaction(
      () => [this.dateTime, this.duration],
      () => {
        if (!this.pending && !this.availableNewEvents.length) {
          this.showAvailableError = true;
        }
      },
    );
  }

  acceptByTerm(event: Event, dateTime: Date = event.start) {
    return (
      this.bookingStore.canEdit &&
      (!this.bookingStore.hasCatering ||
        this.bookingStore.event.status === EventStatus.inApproval ||
        this.bookingStore.event.status === EventStatus.declined ||
        (this.bookingStore.event.status === EventStatus.accepted &&
          Date.now() <
            +dateAdd(
              calcEventPreparationStart(
                dateTime,
                this.bookingStore.event.preparation,
              ),
              -24,
              'hour',
            )))
    );
  }

  @action
  async init(defaultDate?: Date) {
    if (!this.currentEventId) {
      this.dateTime = defaultDate
        ? getFirstDateTime(
            this.bookingStore.minPreparationDuration,
            defaultDate,
          )
        : getFirstDateTime(this.bookingStore.minPreparationDuration);
    } else if (this.currentEventId) {
      this.dateTime = this.bookingStore.event.start;
      this._duration = this.bookingStore.event.duration;
    }

    reaction(
      () => this.date,
      () => {
        this.fetchEvents();
      },
    );

    await this.fetchEvents();
  }

  @action
  async fetchEvents() {
    this.pending = true;

    try {
      const response = await httpFacade.events.fetchSchedulerEvents(this.date);

      this._events = response.data;
    } catch (error) {
      showHttpErrors(error);
      AppRouter.goBack();
    }

    this.pending = false;
  }

  @action.bound
  setPersonAmount(amount: number) {
    if (!this.canEditByTerm) {
      this.showTermError = true;
    } else {
      this.bookingStore.event.personAmount = amount;
    }
  }

  @action.bound
  selectRoom(roomId: string) {
    if (!this.canEditByTerm && this.currentEvent) {
      this.showTermError = true;
    } else {
      const { event, rooms } = this.bookingStore;
      event.resetSelectedRoomIdToSetup();

      if (event.roomsIds.includes(roomId)) {
        event.reservations = event.reservations.filter(
          it => it.room.id !== roomId,
        );
      } else {
        const roomData = rooms.find(room => room.id === roomId) as Room;
        const eventReservationModel = new EventReservationModel({
          roomData,
        });
        event.reservations = [...event.reservations, eventReservationModel];
      }

      event.start = this.dateTime;
      event.duration = this.duration;
      event.canEditCatering = this.canEditCatering();
    }
  }

  canEditCatering() {
    if (!this.bookingStore.event.id && isCateringBlockedByTime(this.dateTime)) {
      return CanEditCateringType.none;
    } else if (
      this.bookingStore.event.id &&
      isCateringEditBlocked(this.dateTime)
    ) {
      return CanEditCateringType.none;
    } else {
      return CanEditCateringType.all;
    }
  }

  @action.bound
  reset() {
    this.duration = 30;
  }
}

export default RoomSelectionStore;
