import * as constants from './constants';
import { UserAuthFormData } from './types';

export const createLoginFormData = ({
  username,
  password,
}: UserAuthFormData): FormData => {
  const formData = new FormData();

  formData.append(constants.FORM_FIELD_USERNAME, username);
  formData.append(constants.FORM_FIELD_PASSWORD, password);
  formData.append(constants.FORM_FIELD_CLIENT_ID, constants.CLIENT_ID_SIONUM);
  formData.append(
    constants.FORM_FIELD_GRANT_TYPE,
    constants.GRANT_TYPE_PASSWORD,
  );

  return formData;
};

export const createRefreshTokenFormData = (refreshToken: string): FormData => {
  const formData = new FormData();

  formData.append(constants.FORM_FIELD_REFRESH_TOKEN, refreshToken);
  formData.append(constants.FORM_FIELD_CLIENT_ID, constants.CLIENT_ID_SIONUM);
  formData.append(
    constants.FORM_FIELD_GRANT_TYPE,
    constants.GRANT_TYPE_REFRESH_TOKEN,
  );

  return formData;
};
