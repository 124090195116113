import React from 'react';
import classNames from 'classnames';
import { FormattedDate, FormattedNumber, FormattedTime } from 'react-intl';

import { Event, EventStatus } from 'types/entities';

import { dateAdd } from 'helpers/datetime';

interface Props {
  item: Event;
  className?: string;
  onClick?: () => void;
}

const EventItem = (props: Props) => {
  const { item, className, onClick } = props;

  return (
    <div
      className={classNames('grid-row', 'item', className, {
        item__accepted: item.status === EventStatus.accepted,
        item__finished: item.status === EventStatus.finished,
        item__inApproval: item.status === EventStatus.inApproval,
        item__declined: item.status === EventStatus.declined,
        item__closed: item.status === EventStatus.closed,
      })}
      onClick={onClick}
    >
      <div className="column justify__between">
        <div className="row wrapper">
          <FormattedDate
            value={item.start}
            year="numeric"
            month="2-digit"
            day="2-digit"
          >
            {txt => <span className="date">{txt}</span>}
          </FormattedDate>
          <FormattedTime value={item.start} />
          &nbsp;-&nbsp;
          <FormattedTime value={dateAdd(item.start, item.duration, 'minute')} />
        </div>
      </div>

      <div className="column">{item.title}</div>
      <div className="column">
        {item.reservations.map(reservation => (
          <p key={reservation.id}>{reservation.room.title}</p>
        ))}
      </div>

      <div className="column">
        <FormattedNumber value={item.personAmount} />
      </div>
    </div>
  );
};

export default EventItem;
