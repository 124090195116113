import React from 'react';
import { action, observable, computed, reaction } from 'mobx';
import { FormattedMessage } from 'react-intl';

import { sortByAccessor } from 'helpers/accessors';
import { dateAdd, dateStartOf, dateEndOf } from 'helpers/datetime';
import {
  FilterPeriods,
  EventStatuses,
  filterByPeriod,
  filterByStatus,
} from 'helpers/filters';
import Log from '../helpers/log';

import httpFacade from 'http/httpFacade';

import { Event, SortConfig } from 'types/entities';

class EventsStore {
  static periodOptions = [
    {
      value: FilterPeriods.thisWeek,
      label: <FormattedMessage id="options.thisWeek" />,
    },
    {
      value: FilterPeriods.nextWeek,
      label: <FormattedMessage id="options.nextWeek" />,
    },
    {
      value: FilterPeriods.later,
      label: <FormattedMessage id="options.later" />,
    },
    {
      value: FilterPeriods.past,
      label: <FormattedMessage id="options.past" />,
    },
  ];

  static statusOptions = [
    {
      value: EventStatuses.all,
      label: <FormattedMessage id="options.all" />,
    },
    {
      value: EventStatuses.inApproval,
      label: <FormattedMessage id="options.inApproval" />,
    },
    {
      value: EventStatuses.approved,
      label: <FormattedMessage id="options.approved" />,
    },
    {
      value: EventStatuses.declined,
      label: <FormattedMessage id="options.declined" />,
    },
    {
      value: EventStatuses.cancelled,
      label: <FormattedMessage id="options.cancelled" />,
    },
  ];

  @observable pending = true;
  @observable period: FilterPeriods | string = FilterPeriods.thisWeek;
  @observable status: EventStatuses | string = EventStatuses.all;
  @observable _data: Event[] = [];
  @observable sortConfig: SortConfig = {
    accessor: 'start',
    desc: false,
  };

  constructor() {
    reaction(
      () => this.period,
      () => this.fetchEvents(),
    );
    reaction(
      () => this.status,
      () => this.fetchEvents(),
    );
  }

  // TODO add end point with optional params start date and end date
  @computed
  get filterStartDate() {
    const now = new Date();

    switch (this.period) {
      case FilterPeriods.thisWeek:
        return dateStartOf(now, 'week');
      case FilterPeriods.nextWeek:
        return dateAdd(dateStartOf(now, 'week'), 1, 'week');
      case FilterPeriods.past:
        return new Date(1950, 0, 1);
      case FilterPeriods.later:
        return dateAdd(dateEndOf(now, 'week'), 1, 'week');
      default:
        return now;
    }
  }

  @computed
  get filterEndDate() {
    const now = new Date();

    switch (this.period) {
      case FilterPeriods.thisWeek:
        return dateEndOf(now, 'week');
      case FilterPeriods.nextWeek:
        return dateAdd(dateEndOf(now, 'week'), 1, 'week');
      case FilterPeriods.past:
        return dateAdd(dateStartOf(now, 'week'), -1, 'day');
      case FilterPeriods.later:
      default:
        return new Date(2050, 0, 1);
    }
  }

  @computed
  get data() {
    return this._data
      .filter(
        event =>
          filterByStatus(this.status, event) &&
          filterByPeriod(this.period, event) &&
          !event.duplicated,
      )
      .sort(sortByAccessor(this.sortConfig));
  }

  @action.bound
  async fetchEvents() {
    this.pending = true;

    try {
      const response = await httpFacade.events.fetchEventsForPeriod(
        this.filterStartDate,
        this.filterEndDate,
      );

      this._data = response.data;
    } catch (error) {
      Log.error(error);
    }

    this.pending = false;
  }
}

export default EventsStore;
