import { action, computed, observable } from 'mobx';
import jwtDecode from 'jwt-decode';

import { TokensResponse, UserAuthFormData } from 'http/Api/User/types';
import httpFacade from 'http/httpFacade';
import { ROUTES } from 'routes/routes';
import { UserPriceGroup } from 'types/entities';

import router from 'stores/AppRouter';

import { Tokens, UserName, JWT } from 'helpers/types';
import { getDefaultRouteByRole, Roles } from 'helpers/roles';
import NotificationStore from './NotificationStore';
import FiltersStore from './FiltersStore';
import StorageStore from './StorageStore';

export const LOGIN_ACCESSOR = 'login';
export const LOGOUT_ACCESSOR = 'logout';
export const ACCESS_TOKEN_ACCESSOR = 'access_token';
export const REFRESH_TOKEN_ACCESSOR = 'refresh_token';

class UserStore {
  static clearUserData() {
    localStorage.removeItem(LOGIN_ACCESSOR);
    localStorage.removeItem(ACCESS_TOKEN_ACCESSOR);
    localStorage.removeItem(REFRESH_TOKEN_ACCESSOR);
  }

  @observable id: string;
  @observable username: UserName;
  @observable roles: Roles[] = [];
  @observable priceGroup: UserPriceGroup;

  constructor() {
    const { accessToken } = this.getAuthTokens();

    if (accessToken) {
      this.init(accessToken);
    }

    StorageStore.subscribe(this.storageListener);
  }

  @action
  init(token: string) {
    const authData = jwtDecode<JWT>(token);
    this.id = authData.userId;
    this.username = authData.user_name;
    this.roles = authData.roles;
    this.priceGroup = authData.priceGroup;

    NotificationStore.start();
  }

  @computed
  get authenticated() {
    return !!this.username;
  }

  setAuthTokens = (tokens: TokensResponse): void => {
    localStorage.setItem(ACCESS_TOKEN_ACCESSOR, tokens.access_token);
    localStorage.setItem(REFRESH_TOKEN_ACCESSOR, tokens.refresh_token);
  };

  getAuthTokens = (): Tokens => ({
    accessToken: localStorage.getItem(ACCESS_TOKEN_ACCESSOR),
    refreshToken: localStorage.getItem(REFRESH_TOKEN_ACCESSOR),
  });

  refreshTokens = async (refreshToken: string): Promise<void> =>
    this.setAuthTokens((await httpFacade.user.refreshToken(refreshToken)).data);

  @action.bound
  async logIn({ username, password }: UserAuthFormData) {
    try {
      const response = await httpFacade.user.login({ username, password });

      localStorage.setItem(LOGIN_ACCESSOR, username);

      this.setAuthTokens(response.data);
      this.init(response.data.access_token);

      router.push(getDefaultRouteByRole());

      StorageStore.subscribe(this.storageListener);

      return response;
    } catch (error) {
      throw new Error(error);
    }
  }

  @action.bound
  logOut() {
    this.username = null;
    this.roles = [];

    localStorage.setItem(LOGOUT_ACCESSOR, `logout-event-${Date.now()}`);

    StorageStore.unsubscribe(this.storageListener);
    FiltersStore.clearFilters();
    UserStore.clearUserData();

    NotificationStore.stop();
    NotificationStore.closeAll();

    router.push(ROUTES.login);
  }

  @action.bound
  private storageListener(event) {
    if (event.key === LOGIN_ACCESSOR) {
      window.location.reload();
    }

    if (event.key === LOGOUT_ACCESSOR) {
      this.logOut();
    }
  }
}

export default UserStore;
