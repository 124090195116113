import React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage, FormattedTime } from 'react-intl';
import classNames from 'classnames';

import style from './Planner.module.scss';

import { MILLISECONDS_PER_MINUTE } from 'helpers/datetime';
import {
  MAIL_FOR_BOOKING_REJECT,
  TEL_FOR_BOOKING_REJECT,
} from 'stores/Booking/config';
import { EventStatus } from 'types/entities';
import { Event } from './types';

function getPreparationHeight(event: Event) {
  return (
    ((event.preparation * MILLISECONDS_PER_MINUTE) /
      (+event.endCleaning - +event.startPreparation)) *
    100
  );
}

function getCleaningHeight(event: Event) {
  return (
    ((event.cleaning * MILLISECONDS_PER_MINUTE) /
      (+event.endCleaning - +event.startPreparation)) *
    100
  );
}

interface Props {
  className?: string;
  event: Event;
  title: string;
}

const EventBooked = observer(({ event }: Props) => {
  const preparationHeight = getPreparationHeight(event);
  const cleaningHeight = getCleaningHeight(event);

  return (
    <div
      className={classNames(style.event__booked, {
        [style.paranja]: event.hidden,
      })}
    >
      <div
        className={style.eventPreparation}
        style={{ height: `${preparationHeight}%` }}
      >
        <FormattedMessage id="event.part.preparation.name" />
      </div>

      <div
        className={classNames(style.event, {
          [style.event__closed]: event.status === EventStatus.declined,
          [style.event__accept]:
            event.status === EventStatus.accepted ||
            event.status === EventStatus.finished,
          [style.event__pending]: event.status === EventStatus.inApproval,
        })}
      >
        <div className={style.eventTime}>
          <FormattedTime value={event.startDate} />
          {' - '}
          <FormattedTime value={event.endDate} />
        </div>
        <div className={style.eventTitle}>{event.title}</div>
        {event.canBeRejected && (
          <div
            className={classNames(style.event__info, style.info__cancellation)}
          >
            <FormattedMessage id="event.booking.optional" />
            <FormattedMessage id="event.booking.optional.description" />
            <a
              href={`mailto:${MAIL_FOR_BOOKING_REJECT}`}
              className={style.link__mail}
            >
              M: {MAIL_FOR_BOOKING_REJECT}
            </a>
            <a
              href={`tel:${TEL_FOR_BOOKING_REJECT}`}
              className={style.link__mail}
            >
              T: {TEL_FOR_BOOKING_REJECT}
            </a>
          </div>
        )}
      </div>
      <div
        className={style.eventCleaning}
        style={{ height: `${cleaningHeight}%` }}
      >
        <FormattedMessage id="event.part.cleaning.name" />
      </div>
    </div>
  );
});

export default EventBooked;
