import React from 'react';
import classNames from 'classnames';

import { User } from 'types/entities';

import style from './UsersPage.module.scss';

interface IProps {
  item: User;
  onClick?: () => void;
}

export function UserItem({ item, onClick }: IProps) {
  return (
    <div className={classNames(style.user, 'grid-row')} onClick={onClick}>
      <div className="column">
        <span>{item.firstName}</span>
      </div>
      <div className="column">
        <span>{item.lastName}</span>
      </div>
      <div className="column">
        <span>{item.email}</span>
      </div>
      <div className="column">
        <span>{item.nickname}</span>
      </div>
      <div className="column">
        <span>{item.company}</span>
      </div>
      <div className="column">
        <span>{item.department}</span>
      </div>
      <div className="column">
        <span>{item.priceGroup}</span>
      </div>
      <div className="column">
        <span>{item.kostenStelleNr}</span>
      </div>
    </div>
  );
}
