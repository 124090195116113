import http from 'http/http';
import { appUrl } from 'http/helpers';
import URLS from 'http/urls';
import { User, UserRole } from 'types/entities';

export interface IUserCreateDto extends Omit<User, 'roles' | 'id'> {
  roleIds: string[];
}

export interface IUserUpdateDto extends Omit<User, 'roles'> {
  roleIds: string[];
}

export const usersApi = {
  fetchRoles() {
    return http.get<UserRole[]>(appUrl(URLS.roles));
  },

  fetchUsers() {
    return http.get<User[]>(appUrl(URLS.users));
  },

  fetchUsersSecretary() {
    return http.get<User[]>(appUrl(URLS.usersSecretary));
  },

  fetchUser(id: string) {
    return http.get<User>(appUrl(URLS.user), {
      replaceParams: { id },
    });
  },

  createUser(data: IUserCreateDto) {
    return http.post<IUserCreateDto, User>(appUrl(URLS.users), data);
  },

  updateUser(data: IUserUpdateDto) {
    return http.put<IUserCreateDto, User>(appUrl(URLS.user), data, {
      replaceParams: { id: data.id },
    });
  },

  deleteUser(id: string) {
    return http.delete(appUrl(URLS.user), {
      replaceParams: { id },
    });
  },
};
