export const FORM_FIELD_USERNAME = 'username';
export const FORM_FIELD_PASSWORD = 'password';
export const FORM_FIELD_CLIENT_ID = 'client_id';
export const FORM_FIELD_GRANT_TYPE = 'grant_type';
export const FORM_FIELD_REFRESH_TOKEN = 'refresh_token';

export const GRANT_TYPE_REFRESH_TOKEN = 'refresh_token';
export const GRANT_TYPE_PASSWORD = 'password';

export const CLIENT_ID_SIONUM = 'sionum';
