import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
  FormattedMessage,
  WrappedComponentProps,
  injectIntl,
} from 'react-intl';

import style from './MenuPage.module.scss';

import Grid from 'components/Grid/Grid';
import Select from 'components/Form/Fields/Select/Select';
import EventItem from 'components/Grid/Items/EventItem';
import { Types } from 'components/Grid/types';
import { DEFAULT_EVENT_SORTING } from 'pages/config';

import EventsStore from 'stores/EventsStore';
import AppRouter from 'stores/AppRouter';
import StorageStore, { FILTERS } from 'stores/FiltersStore';

import { Event } from 'types/entities';

import { ROUTES } from 'routes/routes';

export const eventConfig: Types<Event> = {
  columns: [
    {
      header: <FormattedMessage id="title.date" />,
      id: 'start',
      sortable: true,
    },
    {
      header: <FormattedMessage id="title.event" />,
      id: 'title',
      sortable: true,
    },
    {
      header: <FormattedMessage id="title.room" />,
      id: 'room.title',
      sortable: false,
    },
    {
      header: <FormattedMessage id="title.personAmount" />,
      id: 'personAmount',
      sortable: true,
    },
  ],
  item: EventItem,
};

@observer
class EventsGrid extends Component<WrappedComponentProps> {
  store = new EventsStore();

  async componentDidMount() {
    const { fetchEvents } = this.store;
    const period = StorageStore.getFilter(FILTERS.GRID__EVENTS__PERIOD);
    const status = StorageStore.getFilter(FILTERS.GRID__EVENTS__STATUS);

    if (period) {
      this.store.period = period;
    }

    if (status) {
      this.store.status = status;
    }

    await fetchEvents();
  }

  onItemClick = item => {
    AppRouter.push(ROUTES.event, { id: item.id });
  };

  changePeriod = value => {
    StorageStore.saveFilter(FILTERS.GRID__EVENTS__PERIOD, value);

    this.store.period = value;
  };

  changeStatus = value => {
    StorageStore.saveFilter(FILTERS.GRID__EVENTS__STATUS, value);

    this.store.status = value;
  };

  render() {
    const { data } = this.store;

    return (
      <div className={style.content__events}>
        <div className={style.filters}>
          <FormattedMessage id="eventsList.filters.title" />

          <Select
            className={style.select}
            value={this.store.period}
            onChange={this.changePeriod}
            options={EventsStore.periodOptions}
            disabled={this.store.pending}
          />

          <Select
            className={style.select}
            value={this.store.status}
            onChange={this.changeStatus}
            options={EventsStore.statusOptions}
            disabled={this.store.pending}
          />
        </div>

        <Grid
          loading={this.store.pending}
          data={data}
          config={eventConfig}
          className={style.grid__booking}
          onRowClick={this.onItemClick}
          onSort={config => (this.store.sortConfig = config)}
          defaultSorted={DEFAULT_EVENT_SORTING}
        />
      </div>
    );
  }
}

export default injectIntl(EventsGrid);
