import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';
import classNames from 'classnames';

import { ROUTES } from 'routes/routes';
import AppRouter from 'stores/AppRouter';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import TextField from 'components/Form/Fields/TextField/TextField';
import Select from 'components/Form/Fields/Select/Select';
import Spinner from 'components/Spinner/Spinner';
import AlertModal from 'components/Modals/AlertModal/AlertModal';

import style from './UserPage.module.scss';
import { CreateUserStore } from 'stores/CreateUserStore';

type Props = WrappedComponentProps & RouteComponentProps<{ id: string }>;

@observer
class UserPage extends Component<Props> {
  @observable store = new CreateUserStore({ id: this.props.match.params?.id });
  FormControl = bindFormControl(this.store.form);

  gotoUsersPage = () => {
    AppRouter.push(ROUTES.users);
  };

  onCreateUser = async () => {
    await this.store.createUser(this.gotoUsersPage);
  };

  async componentDidMount() {
    await this.store.init();
  }

  render() {
    const { intl } = this.props;
    const { form } = this.store;
    const FormControl = this.FormControl;
    const placeholderMandatory = intl.formatMessage({
      id: 'user.form.placeholder.mandatory',
    });
    const isEdit = Boolean(form.id);

    return (
      <div className={style.content}>
        <div className={style.header}>
          <Button className={style.buttonArrow} onClick={this.gotoUsersPage}>
            <Icon type="arrowLeft" className={style.iconArrow} />
          </Button>
          <h1 className={style.title}>
            <FormattedMessage
              id={isEdit ? 'title.editUser' : 'title.newUser'}
            />
          </h1>
          <div className={style.controls}>
            <Button styleType="ghost" onClick={this.gotoUsersPage}>
              <FormattedMessage id="button.abort" />
            </Button>
            <Button
              styleType="accept"
              onClick={this.onCreateUser}
              disabled={!form.isFormValid || form.isSelf}
            >
              <FormattedMessage id={isEdit ? 'button.save' : 'button.create'} />
            </Button>
          </div>
        </div>
        <main className={style.main}>
          {this.store.isLoading && <Spinner contain />}
          <form className={style.form}>
            <fieldset className={style.fieldset}>
              <legend className={style.fieldsetLegend}>
                <FormattedMessage id="user.form.legend.basic" />
              </legend>
              <div className={style.formGroup}>
                <div
                  className={classNames(
                    style.wrapper__select,
                    style.select__role,
                  )}
                >
                  <div className={style.select__title}>
                    <FormattedMessage id="title.userRole" />
                  </div>
                  <Select
                    name="role"
                    value={form.roleId}
                    options={this.store.userRolesOptions}
                    classNamePrefix="select"
                    className={style.select}
                    onChange={form.setUserRoleId}
                  />
                </div>
                <div />
                <FormControl
                  name="firstName"
                  className={style.formControl}
                  render={props => (
                    <TextField {...props} placeholder={placeholderMandatory} />
                  )}
                />
                <FormControl
                  name="lastName"
                  className={style.formControl}
                  render={props => (
                    <TextField {...props} placeholder={placeholderMandatory} />
                  )}
                />
                <FormControl
                  name="email"
                  className={style.formControl}
                  render={props => (
                    <TextField {...props} placeholder={placeholderMandatory} />
                  )}
                />
                <FormControl
                  name="nickname"
                  className={style.formControl}
                  render={props => <TextField {...props} />}
                />

                <div className={style.wrapper__select}>
                  <div className={style.select__title}>
                    <FormattedMessage id="title.priceGroup" />
                  </div>
                  <Select
                    name="priceGroup"
                    value={form.priceGroup}
                    options={this.store.priceGroupsOptions}
                    classNamePrefix="select"
                    className={style.select}
                    onChange={form.setPriceGroup}
                  />
                </div>
                <FormControl
                  name="kostenStelleNr"
                  className={style.formControl}
                  render={props => <TextField {...props} />}
                  disabled={!form.isSecretary}
                />
                <FormControl
                  name="company"
                  className={style.formControl}
                  render={props => <TextField {...props} />}
                  disabled={!form.isSecretary}
                />
                <FormControl
                  name="department"
                  className={style.formControl}
                  render={props => <TextField {...props} />}
                  disabled={!form.isSecretary}
                />
              </div>
            </fieldset>
            <fieldset className={style.fieldset}>
              <legend
                className={classNames(
                  style.fieldsetLegend,
                  !form.isSecretary && style.fieldsetLegend__disabled,
                )}
              >
                <FormattedMessage id="user.form.legend.address" />
              </legend>
              <div className={style.fieldsetLine}>
                <FormControl
                  name="country"
                  className={style.formControl}
                  render={props => <TextField {...props} />}
                  disabled={!form.isSecretary}
                />
                <FormControl
                  name="town"
                  className={style.formControl}
                  render={props => <TextField {...props} />}
                  disabled={!form.isSecretary}
                />
              </div>
              <div className={style.fieldsetLine}>
                <FormControl
                  name="streetAndHouseNumber"
                  className={style.formControl}
                  render={props => <TextField {...props} />}
                  disabled={!form.isSecretary}
                />
                <FormControl
                  name="postCode"
                  className={classNames(
                    style.formControl,
                    style.formControl__postCode,
                  )}
                  render={props => <TextField {...props} />}
                  disabled={!form.isSecretary}
                />
              </div>
              <div className={style.fieldsetLine}>
                <FormControl
                  name="additionalAddress"
                  className={classNames(
                    style.formControl,
                    style.additionalAddress,
                  )}
                  render={props => <TextField {...props} />}
                  disabled={!form.isSecretary}
                />
              </div>
            </fieldset>
          </form>
          {isEdit && (
            <Button
              styleType="reject"
              onClick={this.store.deleteUser}
              disabled={form.isSelf}
            >
              <FormattedMessage id="button.deleteUser" />
            </Button>
          )}
        </main>
        {this.store.serverError && (
          <AlertModal
            message={this.store.serverError}
            onClose={this.store.resetErrors}
          />
        )}
      </div>
    );
  }
}

export default injectIntl(UserPage);
