import { action, observable } from 'mobx';

import Localization from './Localization';
import UserStore from './UserStore';

import httpFacade from 'http/httpFacade';
import { convertParamsToObject } from 'http/Api/root/helpers';

import { Time, stringToTime } from 'helpers/datetime';
import Log from 'helpers/log';

interface Config {
  eventTimeMin: Time;
  eventTimeMax: Time;
  eventStep: number;
  eventDurationMin: number;
  eventDurationMax: number;
  eventDurationDefault: number;
  appSchema: 'sionum' | 'mazars' | 'aes';
  agbRules: string;
  coronaRules: string;
  publicHolidays: string[];
}

class RootStore {
  @observable localization = new Localization();
  @observable user = new UserStore();
  @observable config: Config;
  @observable loading = true;
  @observable internalServerError = false;
  @observable accessDeniedError = false;

  @action.bound
  async init() {
    try {
      this.loading = true;
      await Promise.all([this.fetchConfig(), this.localization.init()]);

      this.loading = false;
    } catch (error) {
      Log.error('initialization error');
    }
  }

  @action.bound
  async fetchConfig() {
    try {
      const { data: configParams = [] } = await httpFacade.root.fetchConfig();
      const {
        data: defaultConfig,
      } = await httpFacade.root.fetchDefaultConfig();

      const config = {
        ...defaultConfig,
        ...convertParamsToObject(configParams),
      };

      this.config = {
        ...config,
        eventTimeMin: stringToTime(config.eventTimeMin),
        eventTimeMax: stringToTime(config.eventTimeMax),
        eventStep: Number(config.eventStep),
        eventDurationMin: Number(config.eventDurationMin),
        eventDurationMax: Number(config.eventDurationMax),
        eventDurationDefault: Number(config.eventDurationDefault),
      };

      this.setSchema(this.config.appSchema);
    } catch (error) {
      Log.error(error);
    }
  }

  @action
  setSchema = (schema: string) => {
    const root = document.getElementById('root');
    const title = document.getElementById('app-title');
    const favicon = document.getElementById('app-favicon');

    if (root) {
      root.className = schema;
    }

    if (title && root && favicon) {
      title.innerText = schema;
      favicon.setAttribute('href', `${process.env.PUBLIC_URL}/${schema}.png`);
    }
  };
}

export default new RootStore();
